
import Vue, { PropType } from 'vue'
import { TranslateResult } from 'vue-i18n'
import { EmbedButtonProps } from '~/components/_general/EmbedButton.vue'
import ShareModal from '~/components/_general/ShareModal.vue'
import { siteShareUrl } from '~/assets/ts/utils/urls'
import SaIcon from '~/components/_general/SaIcon.vue'
import InlineIcon from '~/components/_general/InlineIcon.vue'
import SiteButton, { ButtonSize } from '~/components/site/Button.vue'

export default Vue.extend({
  name: 'ShareButton',
  components: { InlineIcon, SaIcon },
  props: {
    qrSize: {
      type: Number,
      default: 132,
    },
    modalClasses: {
      type: String,
      default: '',
    },
    url: {
      type: String,
      default: undefined,
    },
    useSharePrefix: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: Boolean,
      default: true,
    },
    text: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String as PropType<TranslateResult>,
      default: '',
    },
    size: {
      type: String as PropType<ButtonSize>,
      default: 'normal',
    },
    siteButton: {
      type: Boolean,
      default: true,
    },
    ...EmbedButtonProps,
  },
  computed: {
    SiteButton() {
      return SiteButton
    },
    shareUrl(): string | undefined {
      if (this.url && this.useSharePrefix) {
        return siteShareUrl(this.url)
      }
      return this.url
    },
  },
  methods: {
    share() {
      this.$emit('opened')
      this.$modal.show(
        ShareModal,
        {
          qrSize: this.qrSize,
          title: this.title,
          url: this.shareUrl,
          sermon: this.sermon,
          broadcaster: this.broadcaster,
          view: this.view,
        },
        {
          adaptive: true,
          height: 'auto',
          width: '100%',
          class: `share-modal bg-transparent ${this.modalClasses}`,
        }
      )
    },
  },
})
