import { render, staticRenderFns } from "./Collapsible.vue?vue&type=template&id=7d2d9b3c&scoped=true"
import script from "./Collapsible.vue?vue&type=script&lang=ts"
export * from "./Collapsible.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d2d9b3c",
  null
  
)

export default component.exports