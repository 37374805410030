import { render, staticRenderFns } from "./AccordionElement.vue?vue&type=template&id=615bc422&scoped=true"
import script from "./AccordionElement.vue?vue&type=script&lang=ts"
export * from "./AccordionElement.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "615bc422",
  null
  
)

export default component.exports