
import Vue from 'vue'

export default Vue.extend({
  name: 'ExplicitHeightElement',
  props: {
    overrideHeight: {
      type: Number,
      default: undefined,
    },
    update: {
      type: Boolean,
    },
    transitions: {
      type: Boolean,
    },
    /** essentially disabled the dynamic portions of this component */
    staticOpen: {
      type: Boolean,
    },
    /**
     * The height is typically calculated by taking the tallest child's height.
     *
     * This stipulates that the heights should be added together to reach the max height.
     * */
    addChildrenHeights: {
      type: Boolean,
    },
  },
  data() {
    return {
      transitioning: false,
      calculatedHeight: 0,
      loaded: false,
    }
  },
  computed: {
    height(): number {
      return this.overrideHeight !== undefined
        ? this.overrideHeight
        : this.calculatedHeight
    },
  },
  watch: {
    overrideHeight() {
      this.updateHeight()
    },
    update() {
      this.updateHeight()
    },
    transitioning() {
      this.$emit('transitioning', this.transitioning)
    },
  },
  mounted() {
    this.updateHeight()
    this.loaded = true
  },
  methods: {
    updateHeight() {
      if (this.overrideHeight) return
      const el = this.$refs.element as HTMLElement
      let maxHeight = 0
      for (let i = 0; i < el.childElementCount; i++) {
        const height = this.getItemHeight(el.children.item(i))
        if (this.addChildrenHeights) {
          maxHeight += height
        } else {
          maxHeight = Math.max(height, maxHeight)
        }
      }
      this.calculatedHeight = maxHeight
      this.$emit('heightChanged', maxHeight)
    },
    getItemHeight(item: Element | null) {
      if (!item) return 0
      const height = item.scrollHeight
      const style = getComputedStyle(item)
      const marginTop = parseFloat(style.marginTop)
      const marginBottom = parseFloat(style.marginBottom)
      return height + marginTop + marginBottom
    },
  },
})
