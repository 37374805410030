
import Vue from 'vue'
import ShareLink from '~/components/share/Link.vue'
import { EmbedButtonProps } from '~/components/_general/EmbedButton.vue'
import ShareButtons from '~/components/_general/ShareButtons.vue'

export default Vue.extend({
  name: 'ShareModal',
  components: {
    ShareButtons,
    ShareLink,
  },
  props: {
    qrSize: {
      type: Number,
      default: 132,
    },
    modalClasses: {
      type: String,
      default: '',
    },
    url: {
      type: String,
      default: undefined,
    },
    title: {
      type: String,
      default: '',
    },
    ...EmbedButtonProps,
  },
  data() {
    return {
      qrOpen: false,
    }
  },
  watch: {
    '$route.path'() {
      this.$modal.hideAll()
    },
  },
})
