
import Vue from 'vue'
import { wait } from '~/assets/ts/utils/misc'

enum CollapseState {
  Closed,
  Closing,
  Opening,
  Open,
}

export default Vue.extend({
  name: 'Collapsible',
  model: {
    prop: 'collapsed',
    event: 'change',
  },
  props: {
    collapsed: {
      type: Boolean,
      default: true,
    },
    button: {
      type: Boolean,
      default: true,
    },
    prerender: {
      type: Boolean,
    },
    transitions: {
      type: Boolean,
      default: true,
    },
    containerClasses: {
      type: String,
      default: 'duration-300',
    },
  },
  data() {
    const state = (
      this.collapsed ? CollapseState.Closed : CollapseState.Open
    ) as CollapseState
    return {
      contentHeight: 0,
      containerHeight: 0,
      state,
      hasBeenOpened: state === CollapseState.Open,
    }
  },
  computed: {
    render(): boolean {
      return this.hasBeenOpened || this.prerender
    },
    isCollapsed(): boolean {
      return [CollapseState.Closed, CollapseState.Closing].includes(this.state)
    },
    containerStyles(): Record<string, string> {
      switch (this.state) {
        case CollapseState.Closed:
          return { height: '0px' }
        case CollapseState.Closing:
        case CollapseState.Opening:
          return { height: `${this.contentHeight}px` }
        case CollapseState.Open:
        default:
          return {}
      }
    },
  },
  watch: {
    isCollapsed() {
      if (!this.isCollapsed) {
        this.hasBeenOpened = true
      }
    },
    collapsed() {
      if (this.collapsed === this.isCollapsed) return
      this.setCollapsed(this.collapsed)
    },
    async state() {
      if (this.state !== CollapseState.Closing) return
      await wait(10)
      this.state = CollapseState.Closed
    },
  },
  methods: {
    click() {
      this.setCollapsed(!this.isCollapsed)
    },
    setCollapsed(collapsed: boolean) {
      if (this.transitions) {
        this.state = collapsed ? CollapseState.Closing : CollapseState.Opening
      } else {
        this.state = collapsed ? CollapseState.Closed : CollapseState.Open
      }
      this.$emit('change', this.isCollapsed)
    },
    transitionEnded() {
      if (this.state !== CollapseState.Opening) return
      this.state = CollapseState.Open
    },
  },
})
