import { TranslateResult } from 'vue-i18n'
import { Sermon } from '~/models/sermon'
import { Webcast } from '~/models/webcast'
import { HymnApi } from '~/apiclient/apihymns'

export interface DeviceMetadata {
  title?: TranslateResult
  metadata?: string
  series?: string
  image?: string
}

const defaultArt = require('~/assets/images/player/device-generic.jpg')
const artSize = 512

export function SetDeviceSermonMetadata(sermon: Sermon) {
  SetDeviceMetadata(GetSermonMetadata(sermon))
}

export function SetDeviceWebcastMetadata(vue: Vue, webcast: Webcast) {
  SetDeviceMetadata({
    title: webcast.Title(vue),
    metadata: webcast.SpeakerName,
    image: webcast.broadcaster.albumArt(artSize),
  })
}

export function SetDeviceMetadata(metadata: DeviceMetadata) {
  if (!navigator || !('mediaSession' in navigator)) return
  navigator.mediaSession.metadata = new MediaMetadata({
    title: (metadata.title ?? document.title).toString(),
    artist: metadata.metadata,
    album: metadata.series,
    artwork: [
      {
        src: metadata.image ?? defaultArt,
        sizes: `${artSize}x${artSize}`,
        type: 'image/jpeg',
      },
    ],
  })
}

export function GetSermonMetadata(sermon: Sermon): DeviceMetadata {
  return {
    title: sermon.fullTitle,
    metadata: sermon.metadata,
    series: sermon.series?.title,
    image: sermon.speaker.albumArt(artSize),
  }
}

export function GetHymnalMetadata(hymn: HymnApi | undefined): DeviceMetadata {
  const psalm = !!hymn?.psalter
  const metadata = [hymn?.lyricist, hymn?.composer]
  if (!psalm) {
    metadata.push(hymn?.bibleReference)
  }
  const image = `https://media.sermonaudio.com/images/web/images/og-${psalm ? 'psalter' : 'hymn'}.jpg`
  return {
    title: hymn?.title,
    metadata: metadata.filter((m) => !!m).join(' | '),
    image,
  }
}
