
import Vue from 'vue'
import ShareQR from '~/components/share/QR.vue'
import ShareTwitter from '~/components/share/Twitter.vue'
import ShareEmail from '~/components/share/Email.vue'
import ShareFacebook from '~/components/share/Facebook.vue'
import EmbedButton, {
  EmbedButtonProps,
} from '~/components/_general/EmbedButton.vue'

export default Vue.extend({
  name: 'ShareButtons',
  components: { EmbedButton, ShareFacebook, ShareEmail, ShareTwitter, ShareQR },
  props: {
    qrSize: {
      type: Number,
      default: 132,
    },
    url: {
      type: String,
      default: undefined,
    },
    title: {
      type: String,
      default: '',
    },
    ...EmbedButtonProps,
  },
  data() {
    return {}
  },
  computed: {},
  methods: {},
})
