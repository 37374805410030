
import Vue, { PropType } from 'vue'
import { sermonThumbnailUrl } from '~/assets/ts/utils/misc'
import { secondsToHhMmSs } from '~/assets/ts/utils/date'
import { Sermon } from '~/models/sermon'

export default Vue.extend({
  name: 'PlayerPreview',
  props: {
    video: {
      type: Boolean,
    },
    largeAudio: {
      type: Boolean,
    },
    duration: {
      type: Number,
      required: true,
    },
    posterUrl: {
      type: String,
      default: '',
    },
    scrubbing: {
      type: Number,
      default: undefined,
    },
    sermon: {
      type: Object as PropType<Sermon>,
      required: true,
    },
  },
  data() {
    return {
      timeout: 0,
      previewUrl: '',
      loaded: false,
    }
  },
  computed: {
    previewWidth(): number {
      return this.video ? 12 : this.scrubTime < 3600 ? 2.5 : 3.25
    },
    scrubTime(): number {
      if (!this.scrubbing) return 0
      return this.duration * (this.scrubbing * 0.01)
    },
    previewTime(): string {
      return secondsToHhMmSs(this.scrubTime)
    },
  },
  watch: {
    scrubTime() {
      this.updateUrl()
    },
  },
  methods: {
    updateUrl() {
      if (!this.video || this.scrubbing === undefined) return ''
      clearTimeout(this.timeout)
      this.timeout = window.setTimeout(() => {
        this.previewUrl = sermonThumbnailUrl(this.sermon, this.scrubTime, {
          cacheBuster: true,
        })
      }, 300)
    },
  },
})
