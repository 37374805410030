
import Vue, { PropType } from 'vue'
import { Webcast } from '~/models/webcast'
import { WebcastDisplay } from '~/assets/ts/enums'
import { humanizeDuration } from '~/assets/ts/utils/date'
import { Broadcaster } from '~/models/broadcaster'
import { Speaker } from '~/models/speaker'
import {
  siteBroadcasterUrl,
  siteShareUrl,
  siteSpeakerUrl,
} from '~/assets/ts/utils/urls'
import LoadingElement from '~/components/_general/LoadingElement.vue'
import InlineIcon from '~/components/_general/InlineIcon.vue'
import SaLinkOrHref from '~/components/_general/SaLinkOrHref.vue'

export default Vue.extend({
  name: 'WebcastMetadata',
  components: { SaLinkOrHref, InlineIcon, LoadingElement },
  props: {
    webcast: {
      type: Object as PropType<Webcast>,
      default: undefined,
    },
    display: {
      type: Number as PropType<WebcastDisplay>,
      default: WebcastDisplay.LiveExtended,
    },
    distance: {
      type: String,
      default: '',
    },
    smaller: {
      type: Boolean,
    },
    soloUrls: {
      type: Boolean,
    },
  },
  computed: {
    SaLinkOrHref() {
      return SaLinkOrHref
    },
    duration(): string {
      if (!this.webcast) return ''
      if (this.webcast.Duration) {
        return humanizeDuration(this, this.webcast.Duration)
      }
      const start = this.webcast.StartDate
      const end = this.webcast.EndDate ?? new Date()
      const seconds = (end.getTime() - start.getTime()) / 1000
      return humanizeDuration(this, seconds)
    },
    broadcaster(): Broadcaster | undefined {
      return this.webcast?.broadcaster
    },
    speaker(): Speaker | undefined {
      return this.webcast?.speaker
    },
    displayName(): string {
      return this.broadcaster?.displayName ?? ''
    },
    speakerName(): string {
      return this.webcast?.SpeakerName ?? ''
    },
    standard(): boolean {
      return this.display === WebcastDisplay.Standard
    },
    featured(): boolean {
      return this.display === WebcastDisplay.Featured
    },
    video(): boolean {
      return this.display === WebcastDisplay.LiveVideo
    },
    embed(): boolean {
      return this.display === WebcastDisplay.Embed
    },
    broadcasterUrl(): string {
      if (this.soloUrls) {
        return this.broadcaster?.SoloUrl ?? ''
      }
      const url = siteBroadcasterUrl(this.broadcaster)
      return this.embed ? siteShareUrl(url) : url
    },
    speakerUrl(): string {
      if (this.soloUrls) {
        return this.broadcaster
          ? (this.speaker?.getSoloUrl(this.broadcaster) ?? '')
          : ''
      }
      const url = siteSpeakerUrl(this.speaker)
      return this.embed ? siteShareUrl(url) : url
    },
    broadcasterLocation(): string {
      if (!this.embed || this.speakerName) return ''
      return this.webcast?.broadcasterLocation ?? ''
    },
  },
})
